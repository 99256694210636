import React from 'react';
import './styles.scss';
import Sidebar from '../components/Sidebar/Sidebar';

export default function MainLayout({ children }) {
  return (
    <div className="d-flex layout">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content">
        {children}
      </div>
    </div>
  );
}
