import Toast from './components/Toast/Toast';
import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import MainLayout from './layout/layout';
import Loading from './components/Loading/Loading';

const ListMenu = lazy(() => import('./pages/ListMenu'));
const CreateMenu = lazy(() => import('./pages/CreateMenu'));
const CreateHaccp = lazy(() => import('./pages/CreateHaccp'));
const ListHaccp = lazy(() => import('./pages/ListHaccp'));
const Compare = lazy(() => import('./pages/Compare'));

function App() {
  return (
    <>
      <Toast />
      <Routes>
        <Route
          path="/"
          exact
          element={(
            <Suspense>
              <MainLayout />
            </Suspense>
      )}
        />
        <Route
          path="/list-menu"
          exact
          element={(
            <Suspense fallback={<Loading />}>
              <MainLayout>
                <ListMenu />
              </MainLayout>
            </Suspense>
    )}
        />
        <Route
          path="/create-menu"
          exact
          element={(
            <Suspense fallback={<Loading />}>
              <MainLayout>
                <CreateMenu />
              </MainLayout>
            </Suspense>
        )}
        />
        <Route
          path="/edit-menu/:id"
          exact
          element={(
            <Suspense fallback={<Loading />}>
              <MainLayout>
                <CreateMenu />
              </MainLayout>
            </Suspense>
        )}
        />
        <Route
          path="/compare"
          exact
          element={(
            <Suspense fallback={<Loading />}>
              <MainLayout>
                <Compare />
              </MainLayout>
            </Suspense>
        )}
        />
        <Route
          path="/list-haccp"
          exact
          element={(
            <Suspense fallback={<Loading />}>
              <MainLayout>
                <ListHaccp />
              </MainLayout>
            </Suspense>
        )}
        />
        <Route
          path="/create-haccp"
          exact
          element={(
            <Suspense fallback={<Loading />}>
              <MainLayout>
                <CreateHaccp />
              </MainLayout>
            </Suspense>
        )}
        />
        <Route
          path="/edit-haccp/:id"
          exact
          element={(
            <Suspense fallback={<Loading />}>
              <MainLayout>
                <CreateHaccp />
              </MainLayout>
            </Suspense>
        )}
        />
      </Routes>
    </>
  );
}

export default App;
