import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import App from './App';
import { store } from './store';
import reportWebVitals from './reportWebVitals';
import './assets/scss/index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  // <StrictMode>
  <ReduxProvider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ReduxProvider>,
  // </StrictMode>,
);

reportWebVitals();
