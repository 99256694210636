import React from 'react';
import ReactLoading from 'react-loading';

export default function Loading() {
  return (
    <div className="d-flex justify-content-center">
      <ReactLoading type="spinningBubbles" color="#000000" />
    </div>
  );
}
