import React from 'react';
import { NavLink } from 'react-router-dom';
import './styles.scss';

export default function Sidebar() {
  return (
    <div className="sidebar-content">
      <div>
        <NavLink
          to="/"
          style={({ isActive }) => (isActive ? { color: 'blue' } : undefined)}
        >HOME
        </NavLink>
      </div>
      <div>
        <NavLink to="/list-menu" style={({ isActive }) => (isActive ? { color: 'blue' } : undefined)}>メニュー管理</NavLink>
      </div>
      <div>
        <NavLink to="/compare" style={({ isActive }) => (isActive ? { color: 'blue' } : undefined)}>適合率判定</NavLink>
      </div>
      <div>
        <NavLink to="/list-haccp" style={({ isActive }) => (isActive ? { color: 'blue' } : undefined)}>HACCP</NavLink>
      </div>
    </div>
  );
}
